import { React, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
// import { useSelector, useDispatch } from "react-redux";
import data from "../../data.json";
import ScrollToTop from "../ScrollToTop";
import { ReactComponent as Blob } from "../../img/blob.svg";
import { useSelector } from "react-redux";

const ShareTitle = styled.div`
  width: 100%;
  h3 {
    font-weight: 600;
    color: #0d0d86;
    font-size: 1em;
    line-height: 1.9;
  }
`;

const WriteToday = styled.div`
  position: relative;
  width: 100vw;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    0deg,
    rgba(226, 244, 255, 1) 0%,
    rgba(245, 253, 255, 1) 100%
  );
  overflow: hidden;

  @media (orientation: landscape) {
    width: 98vw;
  }
`;

const Container = styled.div`
  position: absolute;
`;

const Backdrop = styled.div`
  position: inherit;
  top: 0;
  left: 0;
  fill: #023f92;
  /* width: 50vmax; */
  width: 400px;
  animation: move 70s ease-in-out infinite;
  transform-origin: 20% 20%;
  filter: blur(15px);

  @keyframes move {
    0% {
      transform: scale(1.3) translate(0vw, 20vh) rotate(-20deg);
    }
    38% {
      transform: scale(2, 1) translate(50vw, 25vh) rotate(160deg);
    }
    40% {
      transform: scale(2, 1) translate(50vw, 25vh) rotate(160deg);
    }
    78% {
      transform: scale(1) translate(-50vw, 15vh);
    }
    80% {
      transform: scale(1) translate(-50vw, 15vh);
    }
    100% {
      transform: scale(1.3) translate(0vw, 20vh) rotate(-20deg);
    }
  }
`;

const CommentCard = styled.div`
  width: ${(props) => (props.open ? "102%" : "100%")};
  background-color: ${(props) => (props.open ? "#f7feff" : "")};
  overflow: hidden;
  margin: 0;
  border-radius: 16px;
  border: ${(props) =>
    props.open ? "solid #cacaca80 1px" : "solid #e2e2e2 1px"};
  display: flex;
  flex-direction: column;
  box-shadow: ${(props) =>
    props.open ? "0px 0px 15px 5px rgba(0, 0, 0, 0.05)" : "none"};
  transition: width 0.4s cubic-bezier(0.51, 0.25, 0, 0.97);

  h3,
  h5,
  h6 {
    margin-block-end: 0em;
    margin-block-start: 0em;
  }

  h5,
  h6 {
    margin-block-end: 0em;
    margin-block-start: 0em;
    color: rgb(100, 110, 109);
    direction: ${(props) => (props.dir ? "rtl" : "ltr")};
  }

  p {
    color: rgb(98, 109, 102);
  }
`;

const ContentsWrap = styled.div`
  padding: 16px;
`;

const CommentCardWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  /* overflow: hidden; */
`;

const CardTitle = styled.div`
  display: flex;
  width: auto;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${(props) => (props.dir ? "row-reverse" : "row")};
  h3 {
    color: #0d0d86;
  }
`;

const TextContent = styled.div`
  max-height: ${(props) => (props.open ? "100%" : "70px")};
  overflow: hidden;
`;

const Button = styled(NavLink)`
  display: flex;
  padding: 10px 20px;
  background-color: #0d0d86;
  font-weight: 600;
  color: white;
  border-radius: 8px;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #3c3cc9;
  }
`;

const Comment = (props) => {
  const [open, setOpen] = useState(false);
  const isRtl = useSelector((state) => state.locale.isRtl);
  const locale = isRtl ? "rtl" : "ltr";

  function MultiLineText({ text, dir }) {
    // 문자열을 줄바꿈 기호 기준으로 분할
    const lines = text.split("\n");

    return (
      <div>
        {lines.map((line, index) => (
          <p dir={dir} key={index}>
            {line}
          </p> // 각 줄을 p 태그로 감싸서 렌더링
        ))}
      </div>
    );
  }

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <CommentCard
      key={props.data.id}
      open={open}
      onClick={toggleOpen}
      dir={isRtl}
    >
      <ContentsWrap>
        <CardTitle dir={isRtl}>
          <h3>{props.data.title}</h3>
          <h5>{props.data.first_name}</h5>
        </CardTitle>
        <TextContent open={open}>
          {open ? (
            <MultiLineText dir={locale} text={props.data.text} />
          ) : (
            <MultiLineText dir={locale} text={props.data.text.slice(0, 20)} />
          )}
        </TextContent>
        {open ? <h5>{props.data.createdAt}</h5> : <h5>...more</h5>}
      </ContentsWrap>
    </CommentCard>
  );
};

const Share = () => {
  const comments = data.map((comment) => (
    <Comment key={comment.idx} data={comment} />
  ));

  return (
    <CommentCardWrap>
      <ScrollToTop />
      <ShareTitle>
        <h3>Today What We Shared</h3>
      </ShareTitle>
      {comments}
      <WriteToday>
        <Backdrop>
          <Blob />
        </Backdrop>
        <Container>
          <Button to="/write">Go to Write</Button>
        </Container>
      </WriteToday>
    </CommentCardWrap>
  );
};

export default Share;

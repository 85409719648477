export const FETCH_COMMENT_REQUEST = "FETCH_COMMENT_REQUEST";
export const FETCH_COMMENT_SUCCESS = "FETCH_COMMENT_SUCCESS";
export const FETCH_COMMENT_FAILURE = "FETCH_COMMENT_FAILURE";

export const fetchComData = (user) => {
  const url = `https://121bible.com/_mis/list_json.php?flag=readResult&RealPid=121bible003855&parent_gubun=3859&uinfo=${user}&pure=1`;

  return async (dispatch) => {
    dispatch({ type: FETCH_COMMENT_REQUEST });

    try {
      const response = await fetch(url);
      const data = await response.json();

      dispatch({ type: FETCH_COMMENT_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: FETCH_COMMENT_FAILURE, payload: error.message });
    }
  };
};

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const commentReducer = (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case FETCH_COMMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case FETCH_COMMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default commentReducer;

import { React, useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import Title from "../Bible/Title";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  SAVE_CONTENTS,
  SAVE_EMAIL,
  TODAY_SENT,
  WRITE_QUESTION,
  WRITE_SHARE,
} from "../../reducer/write";
import { NavLink } from "react-router-dom";
import TodaySent from "./todaySent";
import { OPEN_MODAL } from "../../reducer/modal";
import { fetchComData } from "../../reducer/comment";

const rollUp = keyframes`
from {
    height: 40px;
}
to {
    height: 200px;
}
`;

const rollDown = keyframes`

from {
    max-height: 0px;
}

to {
    max-height: 200px;
}
`;

const PostContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  h3 {
    font-weight: 600;
    color: #0d0d86;
    font-size: 1em;
    line-height: 1.9;
  }
  margin-bottom: 80px;
`;

export const NoUser = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 400px;
  align-items: center;
  justify-content: center;
  h3 {
    font-weight: 600;
    color: #0d0d86;
    font-size: 1em;
    line-height: 1.9;
  }
  margin-bottom: 80px;
`;

export const NoUserText = styled.div`
  width: 100%;
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  h4 {
    color: #0d0d86;
  }
  @media (orientation: portrait) {
    flex-direction: column;
    padding-bottom: 40px;
    gap: 4px;
  }
`;

const ContentTextarea = styled.textarea`
  height: 200px;
  margin-bottom: 8px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  resize: none;
  overflow-y: scroll;
  animation: ${rollUp} 0.5s forwards;
  &::placeholder {
    color: #aaa;
    font-size: 1.2em;
    font-weight: 700;
  }

  /* //스크롤바
  scrollbar-width: thin;
  scrollbar-color: #007bff #f0f0f0;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0px 0px 5px white;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 2px solid transparent;
    background-color: #007bff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #0056b3;
  } */
`;

const Button = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 8px;
`;

const TitleWrap = styled.div`
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: ${(props) =>
      props.fontFamily ? "Noto Sans Arabic" : "Noto Sans"},
    sans-serif;

  h3 {
    font-size: 23pt;
    color: #0d0d86;
  }

  h3,
  h5,
  h6 {
    margin-block-end: 0em;
    margin-block-start: 0em;
  }

  h5 {
    color: #0d0d86;
    margin: 0 8px;
  }
  h6 {
    color: #333333;
  }

  p {
    color: #333333;
    line-height: 33pt;
    font-size: 12pt;
  }

  b {
    font-weight: 600;
    color: #0d0d86;
  }
  @media (orientation: portrait) {
    h3 {
      font-size: 1.5em;
    }
    p {
      font-size: 1em;
      line-height: 1.9;
    }
    /* margin-left: 16px;
    margin-right: 16px; */
  }
`;

const RadioGroup = styled.div`
  display: flex;
  margin: 8px 0;
  /* justify-content: space-around; */
  align-items: center;
  color: #0d0d86;
  height: 40px;
`;

const RadioButtonLabel = styled.label`
  border-radius: 8px;
  padding: 10px 16px 10px 10px;
  background-color: ${(props) =>
    props.selectedOption ? "rgb(217 234 255)" : "none"};
  &:hover {
    background-color: rgb(217 234 255);
  }
`;

const EnterEmail = styled.div`
  animation: ${rollDown} 0.8s forwards;
  background-color: rgb(217 234 255);
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 12px;
  overflow: hidden;
`;

const FlexR = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const EmailInput = styled.input`
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  resize: none;
`;

const ValidationIcon = styled.div`
  margin: 10px;
  width: 20px;
  height: 20px;
  background-color: ${(props) => (props.isValid ? "green" : "red")};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const TypeEmail = styled.h5`
  margin-block-end: 0;
  margin-block-start: 0;
  color: red;
  font-size: xx-small;
`;

export const ButtonNav = styled(NavLink)`
  display: flex;
  padding: 10px 20px;
  background-color: #0d0d86;
  font-weight: 600;
  color: white;
  border-radius: 8px;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #3c3cc9;
  }
`;

const Saved = styled.div`
  color: green;
  opacity: 0;
  animation: fadein 3s;
  -webkit-animation: fadein 3s;

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes fadein {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const Write = () => {
  const [content, setContent] = useState("");
  const [selectedOption, setSelectedOption] = useState("my meditation");
  const [isValidEmail, setIsValidEmail] = useState(false);

  const dispatch = useDispatch();
  const email = useSelector((state) => state.write.email);
  const contents = useSelector((state) => state.write.contents);

  const POST_STORAGE_KEY = "userPostContent";

  const handleContentChange = (e) => {
    const contentsInput = e.target.value;
    localStorage.setItem(POST_STORAGE_KEY, content);
    dispatch({ type: SAVE_CONTENTS, payload: { contents: contentsInput } });
  };

  const handleOptionChange = (e) => {
    if (e.target.value === "question") {
      dispatch({ type: WRITE_QUESTION });
      return setSelectedOption(e.target.value);
    }
    dispatch({ type: WRITE_SHARE });
    setSelectedOption(e.target.value);
  };

  const handleEmailChange = (e) => {
    const emailInput = e.target.value;
    dispatch({ type: SAVE_EMAIL, payload: { email: emailInput } });

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(emailInput));
  };

  const { title, purpose, todaySent } = useSelector((state) => state.write);
  const data = useSelector((state) => state.data.data[0]);
  const { list, id, midx } = useSelector((state) => state.user);
  const comment = useSelector((state) => state.comment.data);

  const writeDate = comment[0]?.midx.slice(0, 10);

  const leftPad = (value) => {
    if (value >= 10) {
      return value;
    }

    return `0${value}`;
  };

  // const toStringByFormatting = (source, delimiter = "-") => {
  //   const year = source.getFullYear();
  //   const month = leftPad(source.getMonth() + 1);
  //   const day = leftPad(source.getDate());

  //   return [year, month, day].join(delimiter);
  // };

  // console.log(writeDate, data.qday);

  const isSame = () => {
    if (writeDate === data.qday) {
      return true;
    } else {
      return false;
    }
  };

  const writeData = {
    midx: `${data?.qday} 00:00:00`,
    QT_idx: midx,
    refidx: "",
    RealPid: "121bible003859",
    uinfo: id,
    purpose: purpose,
    email: email,
    title: title,
    contents: contents,
  };

  const save = async () => {
    const formData = new FormData();
    formData.append("saveList", JSON.stringify(writeData));
    formData.append("key_aliasName", "idx");
    formData.append("key_value", "0");
    formData.append("ActionFlag", "write");
    formData.append("RealPid", "121bible003855");
    formData.append("parent_idx", "midx");

    // if (!formData.uinfo && !accessToken) {
    //   alert("묵상자 정보(uinfo) 또는 QT매니저 로그인 정보가 모두 없습니다!");
    //   return;
    // }

    const token =
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiIxMjFiaWJsZS5jb20iLCJpYXQiOiIxNzExMjk0Mzg5IiwibmN0IjoxNzExMzM1MDUyLCJyZW1lbWJlciI6Im9mZiIsImV4cCI6IjE3MjY4NDYzODkiLCJhdXRoX3ZlcnNpb24iOiIxNTk5NDk1MDMzIiwiTWlzU2Vzc2lvbl9Vc2VySUQiOiJnYWRtaW4iLCJNaXNTZXNzaW9uX1VzZXJOYW1lIjoiZ2FkbWluIiwiTWlzU2Vzc2lvbl9BdXRoU2l0ZSI6ImhvbWUiLCJteUxhbmd1YWdlQ29kZSI6ImtvLUtSIiwiTWlzU2Vzc2lvbl9TdGF0aW9uTmFtZSI6IlJPT1QiLCJNaXNTZXNzaW9uX1N0YXRpb25OdW0iOjI3MiwiTWlzU2Vzc2lvbl9Qb3NpdGlvbk5hbWUiOiJNSVMlRUMlQjUlOUMlRUElQjMlQTAlRUElQjQlODAlRUIlQTYlQUMlRUMlOUUlOTAiLCJNaXNTZXNzaW9uX1Bvc2l0aW9uQ29kZSI6MX0.jwD6uJ8yaSIro6PoGrT65HQekP9w92qECibbv2VdZgg";
    const url = `https://121bible.com/_mis/save.php?parent_gubun=3849&parent_idx=${midx}&accessToken=${token}`;

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });

      const result = await response.json();
      const rr = result["resultCode"];

      dispatch({ type: SAVE_CONTENTS, payload: { contents: "" } });
    } catch (error) {
      console.error("Error during save operation:", error);
      alert("저장 중 오류가 발생했습니다.");
    }
  };

  const selectedDate = useSelector((state) => state.calendar.selectedDate);
  const componentDate = moment(selectedDate).format("DD-MM-YYYY");
  const customUrl = useSelector((state) => state.url.customUrl);
  const isRtl = useSelector((state) => state.locale.isRtl);

  const locale = isRtl ? "rtl" : "ltr";

  useEffect(() => {
    dispatch(fetchComData(id));
    const savedContent = localStorage.getItem(POST_STORAGE_KEY);
    if (savedContent) {
      setContent(savedContent);
    }
  }, []);

  TodaySent();

  const sendingHandler = () => {
    if (isSame() === false) {
      save();
      dispatch({ type: OPEN_MODAL, payload: "A men" });
      localStorage.setItem(POST_STORAGE_KEY, "");
    } else {
      dispatch({
        type: OPEN_MODAL,
        payload: "you already have Medit Today",
      });
    }
  };

  if (list === false) {
    dispatch({
      type: OPEN_MODAL,
      payload: "This service requires a manager.",
    });
    return (
      <NoUser>
        <NoUserText>
          <h4>This service requires a manager.</h4>
        </NoUserText>
        <ButtonNav to={customUrl ? customUrl : "/"}>Home</ButtonNav>
      </NoUser>
    );
  }

  if (data && list) {
    return (
      <PostContainer>
        <TitleWrap fontFamily={locale}>
          <h5>{componentDate}</h5>
          <Title />
          {/* <Saved>{id}</Saved> */}
        </TitleWrap>
        <RadioGroup>
          <RadioButtonLabel selectedOption={selectedOption === "my meditation"}>
            <input
              type="radio"
              value="my meditation"
              checked={selectedOption === "my meditation"}
              onChange={handleOptionChange}
            />
            🙏 My Meditation
          </RadioButtonLabel>
          <RadioButtonLabel selectedOption={selectedOption === "question"}>
            <input
              type="radio"
              value="question"
              checked={selectedOption === "question"}
              onChange={handleOptionChange}
            />
            Question?
          </RadioButtonLabel>
        </RadioGroup>
        {selectedOption === "question" && (
          <EnterEmail>
            <h3>Please enter your email address to receive a reply</h3>
            <FlexR>
              <EmailInput
                type="email"
                placeholder="example@email.com"
                value={email}
                onChange={handleEmailChange}
              />
              <ValidationIcon isValid={isValidEmail}>
                {isValidEmail ? "✓" : "✗"}
              </ValidationIcon>
              {!isValidEmail && (
                <TypeEmail>Please write in email format</TypeEmail>
              )}
            </FlexR>
          </EnterEmail>
        )}
        <ContentTextarea
          dir={locale}
          placeholder={
            "يرجى كتابة مشاعرك اليوم. سيتم إرسال الأسئلة إلى مديرك، ويمكنك فقط التحقق من محتوى التأمل الخاص بك."
          }
          value={contents}
          onChange={handleContentChange}
        />
        <Button onClick={sendingHandler}>Write</Button>
      </PostContainer>
    );
  }
};

export default Write;

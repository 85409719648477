import { useEffect } from "react";
import { TODAY_SENT } from "../../reducer/write";
import { useDispatch } from "react-redux";

const TodaySent = () => {
  const dispatch = useDispatch();

  const todayUrl =
    "https://121bible.com/_mis/list_json.php?flag=readResult&RealPid=121bible003855&parent_gubun=3859&parent_idx=2024-04-08%2000:00:00&pure=1";

  const getTodayWriteData = async (url) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      dispatch({ type: TODAY_SENT, payload: { todaySent: data.length } });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getTodayWriteData(todayUrl);
  }, []);
  return null;
};

export default TodaySent;

import { combineReducers } from "redux";
import modalReducer from "./modal";
import dataReducer from "./data";
import { dateReducer } from "./calendar";
import commentReducer from "./comment";
import localeReducer from "./locale";
import urlReducer from "./customUrl";
import writeReducer from "./write";
import userReducer from "./user";

const rootReducer = combineReducers({
  modal: modalReducer,
  data: dataReducer,
  write: writeReducer,
  comment: commentReducer,
  calendar: dateReducer,
  locale: localeReducer,
  url: urlReducer,
  user: userReducer,
});

export default rootReducer;

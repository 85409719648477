export const USER_DATA_REQ = "USER_DATA_REQ";
export const USER_DATA_SUCCESS = "USER_DATA_SUCCESS";
export const USER_DATA_FAILURE = "USER_DATA_FAILURE";
export const USER_DATA_ID = "USER_DATA_ID";
export const USER_DATA_MIDX = "USER_DATA_MIDX";

export const getUserData = (uurl) => {
  const url = uurl;

  return async (dispatch) => {
    dispatch({ type: USER_DATA_REQ });

    try {
      const response = await fetch(url);
      const data = await response.json();

      dispatch({ type: USER_DATA_SUCCESS, payload: data.user });
    } catch (error) {
      dispatch({ type: USER_DATA_FAILURE, payload: error.message });
    }
  };
};

const initialState = {
  midx: null,
  id: null,
  list: false,
  loading: false,
  error: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_DATA_REQ:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };

    case USER_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: { message: action.payload, actualError: action.error },
      };

    case USER_DATA_ID:
      return {
        ...state,
        id: action.payload,
      };

    case USER_DATA_MIDX:
      return {
        ...state,
        midx: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;

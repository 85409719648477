export const OPEN_CALENDAR = "OPEN_CALENDAR";
export const OPEN_LANGUAGE = "OPEN_LANGUAGE";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const OPEN_MODAL = "OPEN_MODAL";

const initialState = {
  isShow: false,
  data: "",
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CALENDAR:
      return {
        ...state,
        isShow: "calendar",
      };
    case OPEN_LANGUAGE:
      return {
        ...state,
        isShow: "language",
      };
    case OPEN_MODAL:
      return {
        ...state,
        isShow: "modal",
        data: action.payload,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        isShow: false,
      };

    default:
      return state;
  }
};

export default modalReducer;

import React from "react";
import convertToArabicNumbers from "./arabic.convert";
import { BibleTextSkel, SkelWrap } from "./BibleText.style";
import { useSelector } from "react-redux";
import NotReady from "../NotReady";

/**
 * 역슬래쉬n(띄어쓰는 마크업)을 맵핑해서 줄단위로 반환함. 그리고 줄바꿈 시킴.
 * @param {*} text 문자열 타입만 넣는다. 객체나 배열은 안됨.
 * @returns 마크업으로 반환된다.
 */
const splitTextIntoSpans = (text) => {
  return text
    ? text.split("\n").map((line, index) => (
        <span key={index}>
          <span dangerouslySetInnerHTML={{ __html: line }} />
          <br />
        </span>
      ))
    : null;
};

const BibleText = () => {
  const apiData = useSelector((state) => state.data.data);
  const loading = useSelector((state) => state.data.loading);
  const isRtl = useSelector((state) => state.locale.isRtl);

  const locale = isRtl ? "rtl" : "ltr";
  const body = apiData[0]?.zbible_text;
  const medit = apiData[0]?.ztoday_medit;
  const apply = apiData[0]?.zapplication;
  const pray = apiData[0]?.ztoday_pray;

  if (apiData && medit) {
    const a = convertToArabicNumbers(medit);
    const b = convertToArabicNumbers(apply);
    const c = convertToArabicNumbers(pray);
    const d = convertToArabicNumbers(body);

    return (
      <>
        <p dir={locale}>{splitTextIntoSpans(locale === "rtl" ? d : body)}</p>
        <br />
        <h3 dir={locale}>😌</h3>
        <p dir={locale}>{splitTextIntoSpans(locale === "rtl" ? a : medit)}</p>
        <br />
        <h3 dir={locale}>💪</h3>
        <p dir={locale}>{splitTextIntoSpans(locale === "rtl" ? b : apply)}</p>
        <br />
        <h3 dir={locale}>🙏</h3>
        <p dir={locale}>{splitTextIntoSpans(locale === "rtl" ? c : pray)}</p>
      </>
    );
  } else if (loading) {
    const rl = isRtl ? "flex-end" : "flex-start";
    return (
      <SkelWrap style={{ alignItems: rl }}>
        <BibleTextSkel width={Math.floor(Math.random() * 100) - 10 + "%"} />
        <BibleTextSkel width={Math.floor(Math.random() * 100) - 10 + "%"} />
        <BibleTextSkel width={Math.floor(Math.random() * 100) - 10 + "%"} />
        <BibleTextSkel width={Math.floor(Math.random() * 100) - 10 + "%"} />
        <BibleTextSkel width={Math.floor(Math.random() * 100) - 10 + "%"} />
        <BibleTextSkel width={Math.floor(Math.random() * 100) - 10 + "%"} />
        <BibleTextSkel width={Math.floor(Math.random() * 100) - 10 + "%"} />
        <BibleTextSkel width={Math.floor(Math.random() * 100) - 10 + "%"} />
        <BibleTextSkel width={Math.floor(Math.random() * 100) - 10 + "%"} />
        <BibleTextSkel width={Math.floor(Math.random() * 100) - 10 + "%"} />
        <BibleTextSkel width={Math.floor(Math.random() * 100) - 10 + "%"} />
        <BibleTextSkel width={Math.floor(Math.random() * 100) - 10 + "%"} />
        <BibleTextSkel width={Math.floor(Math.random() * 100) - 10 + "%"} />
        <BibleTextSkel width={Math.floor(Math.random() * 100) - 10 + "%"} />
        <h3>😌</h3>
        <BibleTextSkel width={Math.floor(Math.random() * 100) - 10 + "%"} />
        <BibleTextSkel width={Math.floor(Math.random() * 100) - 10 + "%"} />
        <BibleTextSkel width={Math.floor(Math.random() * 100) - 10 + "%"} />

        <h3>💪</h3>
        <BibleTextSkel width={Math.floor(Math.random() * 100) - 10 + "%"} />
        <BibleTextSkel width={Math.floor(Math.random() * 100) - 10 + "%"} />
        <BibleTextSkel width={Math.floor(Math.random() * 100) - 10 + "%"} />

        <h3>🙏</h3>
        <BibleTextSkel width={Math.floor(Math.random() * 100) - 10 + "%"} />
        <BibleTextSkel width={Math.floor(Math.random() * 100) - 10 + "%"} />
        <BibleTextSkel width={Math.floor(Math.random() * 100) - 10 + "%"} />
      </SkelWrap>
    );
  }

  if (body === undefined) {
    return <NotReady />;
  }
};

export default BibleText;

import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Logo } from "../img/swim_logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { OPEN_MODAL } from "../reducer/modal";
// import { ReactComponent as Planner } from "../img/planner.svg";

const Foot = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 80px;

  p,
  h3 {
    margin-block-end: 0;
    margin-block-start: 0;
    color: #555;
  }
  h3 {
    font-style: italic;
  }
  p {
    font-size: 0.8em;
    line-height: 1.5;
  }
  @media (orientation: portrait) {
    margin-bottom: 120px;
  }
`;

const FooWrap = styled.div`
  max-width: 768px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  @media (orientation: portrait) {
    flex-direction: column;
  }
`;

const FooHead = styled.div`
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const Copyright = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  @media (orientation: portrait) {
    margin: 0;
  }
`;

const PlannerText = styled.div`
  border-top: solid #e5e5e5 0.5px;
  /* border-bottom: solid #e5e5e5 0.5px; */
  width: 100%;
  margin: auto;
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  h4 {
    color: #0d0d86;
  }
  @media (orientation: portrait) {
    flex-direction: column;
    padding-bottom: 20px;
    gap: 4px;
  }
`;

const Button = styled.button`
  display: flex;
  padding: 10px 20px;
  background-color: rgb(217 234 255);
  font-weight: 600;
  color: #0d0d86;
  border-radius: 8px;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: rgb(240, 245, 252);
  }
`;

// const PlannerImg = styled(Planner)`
//   width: 200px;
// `;

const LogoImg = styled(Logo)`
  width: 80px;
`;

const Footer = () => {
  const { lang } = useSelector((state) => state.locale);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const midx = searchParams.get("midx");
  const uinfo = searchParams.get("uinfo");
  const dispatch = useDispatch();

  const refuseText = {
    fusha: [
      "إذا كنت تشعر بأن الحصول على رسال الانجيل اليومية هو عبء عليك، يمكنك الضغط على زر “إلغاء الاشتراك” الان لإيقاف استقبال هذه الرسائل.",
      "إلغاء الاشتراك",
    ],
    levantine: [
      "إذا كنت تشعر بأن الحصول على رسال الانجيل اليومية هو عبء عليك، يمكنك الضغط على زر “إلغاء الاشتراك” الان لإيقاف استقبال هذه الرسائل.",
      "إلغاء الاشتراك",
    ],
    dari: [
      "اگر گرفتن انجیل برای شما سنگین است، لطفاً روی دکمه زیر کلیک کنید.",
      "لغو اشتراک",
    ],
    turkiye: [
      "İncil almak sizin için bir yükse, lütfen aşağıya tıklayın.",
      "Abonelikten çık",
    ],
  };

  const refuseHandler = () => {
    if (midx && uinfo) {
      if (window.confirm(`${refuseText[lang][1]}?`)) {
        window.open(
          `https://121bible.com/refuse?midx=${midx}&uinfo=${uinfo}&step=2`
        );
      }
    } else {
      dispatch({
        type: OPEN_MODAL,
        payload: "You are not Subscriber",
      });
    }
  };

  const misHandler = () => {
    window.open("https://121bible.com/_mis/");
  };

  return (
    <Foot>
      {lang ? (
        <PlannerText>
          <h4>{refuseText[lang][0]}</h4>
          <Button onClick={refuseHandler}>{refuseText[lang][1]}</Button>
        </PlannerText>
      ) : (
        ""
      )}
      <PlannerText>
        {/* <PlannerImg loading="lazy" /> */}
        <h4>Are you a QT Plan Manager?</h4>
        <Button onClick={misHandler}>Go to Manager Page</Button>
      </PlannerText>
      <FooWrap>
        <FooHead>
          <p>
            Data provider: <Link to="https://www.duranno.com/">Duranno</Link>
            <br />
            The text content copyright belongs to Duranno.
            <br />
            <br />
            Translation provider: <Link to="https://swim.org">SWIM.org</Link>
            <br />
            Using content must require Permission from the Organization.
          </p>
        </FooHead>
        <Copyright>
          <LogoImg loading="lazy" />
          <p>
            (+82) 02-796-3217 | swim@swim.org <br />
            Copyright 2009. SWIM all rights reserved.
            {/* <br />
            <br />
            <Link to="https://pf.kakao.com/_xkcaxeK">Kakao Channel</Link>
            <br />
            <Link to="/policy">Read Policy (Kor only)</Link> */}
          </p>
        </Copyright>
      </FooWrap>
    </Foot>
  );
};

export default Footer;

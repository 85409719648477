export const LOCALE_ARABIC = "LOCALE_ARABIC";
export const LOCALE_TURKIYE = "LOCALE_TURKIYE";
export const LOCALE_DARI = "LOCALE_DARI";
export const LOCALE_LEVANTINE = "LOCALE_LEVANTINE";

const initialState = {
  isRtl: false,
  lang: null,
};

const localeReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCALE_ARABIC:
      return {
        ...state,
        isRtl: true,
        lang: "fusha",
      };
    case LOCALE_TURKIYE:
      return {
        ...state,
        isRtl: false,
        lang: "turkiye",
      };
    case LOCALE_DARI:
      return {
        ...state,
        isRtl: true,
        lang: "dari",
      };
    case LOCALE_LEVANTINE:
      return {
        ...state,
        isRtl: true,
        lang: "levantine",
      };

    default:
      return state;
  }
};

export default localeReducer;

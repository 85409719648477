import styled, { keyframes } from "styled-components";

export const CardTitle = styled.div`
  display: flex;
  width: 100%;
  min-height: 80px;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(
    300deg,
    rgba(197, 209, 255, 1) 0%,
    rgba(213, 248, 255, 1) 100%
  );
  border-radius: 16px;
  margin-bottom: 16px;
  color: #0d0d86;
  padding-top: 8px;
  padding-bottom: 16px;

  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);

  h3 {
    margin-left: 16px;
    margin-right: 16px;
  }
  h4 {
    margin-left: 16px;
    margin-right: 16px;
  }

  @media (orientation: portrait) {
    /* width: 100%; */
  }
`;

export const CardSubtitle = styled.div`
  gap: 12px;
  h4 {
    margin-block-end: 0;
    margin-block-start: 0;
  }
`;

const slideAnimation = keyframes`
  0% {
    margin-left: 5%;
    margin-right: 85%;
    width: 10%;
    opacity: 1;
  }

  25% {
    margin-left: 20%;
    margin-right: 20%;
    width: 60%;
    opacity: 0.2;
  }

  50%{
    margin-left: 85%;
    margin-right: 5%;
    width: 10%;
    opacity: 1;
  }

  75% {
    margin-left: 20%;
    margin-right: 20%;
    width: 60%;
    opacity: 0.2;
  }

  100% {
    margin-left: 5%;
    margin-right: 85%;
    width: 10%;
    opacity: 1;
  }

`;

export const BibleTitleSkel = styled.div`
  margin-top: 10px;
  height: 35px;
  background-color: rgba(13, 13, 134, 0.2);
  border-radius: 10px;
  animation: ${slideAnimation} 2s infinite;
`;

export const BibleHeaderSkel = styled.div`
  margin-top: 15px;
  height: 23px;
  background-color: rgba(13, 13, 134, 0.2);
  border-radius: 10px;
  animation: ${slideAnimation} 2s infinite;
`;

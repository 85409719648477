import styled, { keyframes } from "styled-components";

const slideAnimation = keyframes`
  0% {
    opacity: 1;
  }


  50%{
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }

`;

export const BibleTextSkel = styled.div`
  width: ${(props) => props.width};
  margin-top: 12px;
  height: 30px;
  background-color: rgba(1, 1, 1, 0.2);
  border-radius: 8px;
  animation: ${slideAnimation} 1s infinite;
`;

export const SkelWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

import styled from "styled-components";

const ModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 150%;
  display: flex;
  background-color: #00000045;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  z-index: 100;
  flex-direction: column;
  /* transition: opacity 0.5s cubic-bezier(0.51, 0.25, 0, 0.97); */
  align-items: center;
  /* justify-content: center; */
  animation: fade 0.3s cubic-bezier(0.51, 0.25, 0, 0.97);

  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 97%;
    }
  }
`;

export default ModalWrap;

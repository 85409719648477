import React from "react";
import CalendarPicker from "../Cal";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { CLOSE_MODAL } from "../../reducer/modal";
import ModalWrap from "./Modal.style";
import Locale from "../Locale";
import moment from "moment";
import { NavLink } from "react-router-dom";

const CloseButton = styled.div`
  right: 0;
  cursor: pointer;
  width: 120px;
  height: 45px;
  /* background-color: ${(props) =>
    props.isDateSame ? "#72ad73" : "#38ff3b"}; */
  background-color: #55ac35;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  font-size: 1.2em;
  /* box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.1); */

  animation: slideRight 0.3s;

  @keyframes slideRight {
    from {
      transform: translateX(120%);
    }
    to {
      transform: translateX(0);
    }
  }

  &:hover {
    background-color: #68cc45;
  }
`;

const HandleClick = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

const HandleClickLang = styled(HandleClick)`
  width: 100%;
  max-width: 630px;
`;

// const CalWrap = styled.div`
//   position: absolute;
//   top: ${(props) => (props.isShow ? "0" : "-100%")};
//   transition: top 0.5s cubic-bezier(0.51, 0.25, 0, 0.97);
// `;

const ContentsWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #00000045;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  z-index: 100;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fade 0.3s cubic-bezier(0.51, 0.25, 0, 0.97);

  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 97%;
    }
  }
`;

const ContentBody = styled.div`
  background-color: white;
  border-radius: 12px;
  min-width: 230px;
  height: auto;
  padding: 16px;
  h4 {
    color: #0d0d86;
  }
`;

const ButtonNav = styled(NavLink)`
  display: flex;
  padding: 10px 20px;
  background-color: #0d0d86;
  font-weight: 600;
  color: white;
  border-radius: 8px;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #3c3cc9;
  }
`;

function Modal() {
  const isShow = useSelector((state) => state.modal.isShow);
  const data = useSelector((state) => state.modal.data);
  const thisDate = useSelector((state) => state.calendar.selectedDate);
  const isDateSame = moment(thisDate).format("YYYY-MM-DD");
  const customUrl = useSelector((state) => state.url.customUrl);

  const dispatch = useDispatch();
  const handleParentClick = (event) => {
    event.stopPropagation();
  };

  const closeModal = () => {
    dispatch({
      type: CLOSE_MODAL,
    });
  };

  if (!isShow) return <></>;
  else if (isShow === "calendar") {
    return (
      <ModalWrap onClick={closeModal}>
        <HandleClick onClick={handleParentClick}>
          <CalendarPicker />
          <CloseButton onClick={closeModal} props={isDateSame}>
            <b>Apply</b>
          </CloseButton>
        </HandleClick>
      </ModalWrap>
    );
  } else if (isShow === "language") {
    return (
      <ModalWrap onClick={closeModal}>
        <HandleClickLang onClick={handleParentClick}>
          <Locale />
        </HandleClickLang>
      </ModalWrap>
    );
  } else if (isShow === "modal") {
    const closeHandler = () => {
      dispatch({
        type: CLOSE_MODAL,
      });
    };
    return (
      <ContentsWrap onClick={closeModal}>
        <HandleClick onClick={handleParentClick}>
          <ContentBody>
            <h4>{data}</h4>
            <ButtonNav onClick={closeHandler} to={customUrl ? customUrl : "/"}>
              Home
            </ButtonNav>
          </ContentBody>
        </HandleClick>
      </ContentsWrap>
    );
  }
}
export default Modal;

// import React, { useEffect } from "react";
import styled from "styled-components";

const AudioComp = styled.audio`
  width: 100%;
  height: 50px;
  border-radius: 40px;
  margin: 8px 0;
  &::-webkit-media-controls-panel {
    background: #bfc5de;
  }
`;

// const AudioSkltn = styled.div`
//   width: 100%;
//   height: 50px;
//   border-radius: 40px;
//   margin: 8px 0;
//   background: linear-gradient(
//     215deg,
//     rgba(233, 234, 241, 1) 0%,
//     rgba(234, 238, 220, 1) 100%
//   );
//   color: #909090;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

const Audio = (url) => {
  // console.log(url.url);
  if (!url.url) {
    return <></>;
  } else if (url) {
    return <AudioComp controls download src={url.url} type="audio/mpeg" />;
  }
};

export default Audio;

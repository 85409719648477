/**
 * 모든 숫자를 아라빅 숫자로 바꿈
 * @param {*} text 문자열 타입만 넣는다. 객체나 배열은 안됨.
 * @returns 문자열로 준다.
 */
const convertToArabicNumbers = (text) => {
  const arabicNumbers = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
  const textData = text ? text.toString() : null;
  const convertedText = textData
    ? textData.replace(/[0-9]/g, (match) => arabicNumbers[match])
    : null;
  return convertedText;
};

export default convertToArabicNumbers;

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as HomeIco } from "../img/home.svg";
import { ReactComponent as BookIco } from "../img/book_open.svg";
import { ReactComponent as ComtIco } from "../img/message_square.svg";
import { ReactComponent as PostIco } from "../img/edit.svg";
import { ReactComponent as MyIco } from "../img/my.svg";

const TabBody = styled.div`
  max-width: 570px;
  margin: 0;
  width: ${(props) => (props.isPortrait ? "100%" : "64px")};
  height: ${(props) => (props.isPortrait ? "60px" : "200px")};
  position: fixed;
  bottom: ${(props) => (props.isPortrait ? "-1px" : "")};
  left: ${(props) => (props.isPortrait ? "50%" : "50%")};
  transform: ${(props) =>
    props.isPortrait ? "translateX(-50%)" : "translateX(-375px)"};
  /* border-radius: ${(props) => (props.isPortrait ? "0" : "16px")}; */
  border-radius: 23px;
  /* box-shadow: ${(props) =>
    props.isPortrait ? "none" : "0 0 12px 5px rgba(0,0,0,0.05)"}; */
  z-index: 90;
  border: ${(props) =>
    props.isPortrait ? "solid 1px rgba(0, 0, 0, 0.1)" : "none"};

  display: flex;
  /* gap: 4px; */
  justify-content: space-evenly;
  align-items: center;
  flex-direction: ${(props) => (props.isPortrait ? "row" : "column")};
  padding: ${(props) => (props.isPortrait ? "none" : "10px 0")};
  margin-bottom: 20px;

  background-color: rgba(245, 253, 255, 0.6);
  /* background-color: ${(props) =>
    props.isPortrait ? "rgba(245, 253, 255, 0.6)" : "rgb(217 234 255)"}; */
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  /*  
  transition:
height 0.5s cubic-bezier(0.51, 0.25, 0, 0.97),
    width 0.5s cubic-bezier(0.51, 0.25, 0, 0.97); */
  @media (orientation: landscape) {
    background-color: "white";
  }
  @media all and (max-width: 569px) {
    width: 90vw;
  }
  &:hover {
    @media (orientation: landscape) {
      background-color: rgba(213, 248, 255, 0.4);
    }
  }
`;

const TabBtn = styled(NavLink)`
  width: 60px;
  height: 45px;
  cursor: pointer;
  display: flex;
  /* justify-content: ${(props) =>
    props.isPortrait ? "flex-start;" : "center"}; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  font-size: x-small;
  color: #9b9bca;
  /* margin-bottom: ${(props) => (props.isPortrait ? "10px" : "0")}; */
  &.active {
    color: #0d0d86;
    stroke: #0d0d86;
    fill: none;
  }
  &:not(.active) {
    stroke: #a7a7d8;
    fill: none;
  }
`;

const Home = styled(HomeIco)`
  width: 40%;
  height: auto;
`;
const CommentIco = styled(ComtIco)`
  width: 40%;
  height: auto;
`;
const Post = styled(PostIco)`
  width: 40%;
  height: auto;
`;
const Book = styled(BookIco)`
  width: 40%;
  height: auto;
`;
const My = styled(MyIco)`
  width: 40%;
  height: auto;
`;

const Tabbar = () => {
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  const customUrl = useSelector((state) => state.url.customUrl);
  const dispatch = useDispatch();

  useEffect(() => {
    const portraitMediaQuery = window.matchMedia("(orientation: portrait)");

    const updateOrientation = () => {
      setIsPortrait(portraitMediaQuery.matches);
    };
    updateOrientation(); // 초기 설정
    portraitMediaQuery.addListener(updateOrientation);

    return () => {
      portraitMediaQuery.removeListener(updateOrientation);
    };
  }, [dispatch]);

  return (
    <TabBody isPortrait={isPortrait}>
      {/* <TabBtn isPortrait={isPortrait} to={customUrl ? customUrl : "/"}>
        <Home />
        home
      </TabBtn> */}
      <TabBtn isPortrait={isPortrait} to={customUrl ? customUrl : "/"}>
        <Book />
        Book
      </TabBtn>
      <TabBtn isPortrait={isPortrait} to="/share">
        <CommentIco />
        Share
      </TabBtn>
      <TabBtn isPortrait={isPortrait} to="/write">
        <Post />
        Write
      </TabBtn>
      <TabBtn isPortrait={isPortrait} to="/my">
        <My />
        My Medit
      </TabBtn>
    </TabBody>
  );
};

export default Tabbar;

export const CUSTOM_URL = "CUSTOM_URL";

const initialState = {
  customUrl: "/",
};

const urlReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOM_URL:
      return {
        ...state,
        customUrl: action.payload,
      };

    default:
      return state;
  }
};

export default urlReducer;

import React from "react";
import convertToArabicNumbers from "./arabic.convert";
import {
  BibleHeaderSkel,
  BibleTitleSkel,
  CardSubtitle,
  CardTitle,
} from "./Title.style";
import { useSelector } from "react-redux";

const BibleTitle = (text) => {
  const a = text.text?.title;
  return <h3 dir={text.text?.locale}>{a}</h3>;
};

const BibleHeader = (text) => {
  const a = `${text.text?.chapter_start} : ${text.text?.verse_start} ~ 
  ${text.text?.verse_end}`;
  const b = `${text.text?.chapter_start} : ${text.text?.verse_start} ~ 
  ${text.text?.chapter_end} : ${text.text?.verse_end}`;

  if (text.text?.chapter_start === text.text?.chapter_end) {
    return (
      <h4 dir={text.text?.locale}>
        {text.text?.bible}
        {"  "}
        {text.text?.locale === "rtl" ? convertToArabicNumbers(a) : a}
      </h4>
    );
  } else {
    return (
      <h4 dir={text.text?.locale}>
        {text.text?.bible}
        {"  "}
        {text.text?.locale === "rtl" ? convertToArabicNumbers(b) : b}
      </h4>
    );
  }
};

const Title = () => {
  const apiData = useSelector((state) => state.data.data);
  const loading = useSelector((state) => state.data.loading);
  const locale = useSelector((state) => state.locale.isRtl);

  const text = {
    title: apiData[0]?.ztitle,
    chapter_start: apiData[0]?.table_mQmbible_start_jang1,
    chapter_end: apiData[0]?.table_mQmbible_end_jang1,
    verse_start: apiData[0]?.table_mQmbible_start_jul1,
    verse_end: apiData[0]?.table_mQmbible_end_jul1,
    bible: apiData[0]?.zbiblename,
    locale: locale ? "rtl" : "ltr",
  };

  if (text.title) {
    return (
      <CardTitle>
        <BibleTitle text={text} />
        <CardSubtitle>
          <BibleHeader text={text} />
        </CardSubtitle>
      </CardTitle>
    );
  }

  if (loading) {
    return (
      <CardTitle>
        <BibleTitleSkel />
        <CardSubtitle>
          <BibleHeaderSkel />
        </CardSubtitle>
      </CardTitle>
    );
  }
};

export default Title;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { fetchComData } from "../reducer/comment";
import { useDispatch, useSelector } from "react-redux";
import { NoUser, NoUserText } from "./Write";
import { NavLink } from "react-router-dom";
import DeletePost from "./Write/delete";

const MyWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 80px;
`;

const MyTitle = styled.div`
  width: 100%;
  h3 {
    font-weight: 600;
    color: #0d0d86;
    font-size: 1em;
    line-height: 1.9;
  }
`;

const ButtonNav = styled(NavLink)`
  display: flex;
  padding: 10px 20px;
  background-color: #0d0d86;
  font-weight: 600;
  color: white;
  border-radius: 8px;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #3c3cc9;
  }
`;

const CommentCard = styled.div`
  width: ${(props) => (props.open ? "102%" : "100%")};
  background-color: ${(props) => (props.open ? "#f7feff" : "")};
  overflow: hidden;
  margin: 0;
  border-radius: 16px;
  border: ${(props) =>
    props.open ? "solid #cacaca80 1px" : "solid #e2e2e2 1px"};
  display: flex;
  flex-direction: column;
  box-shadow: ${(props) =>
    props.open ? "0px 0px 15px 5px rgba(0, 0, 0, 0.05)" : "none"};
  transition: width 0.4s cubic-bezier(0.51, 0.25, 0, 0.97);

  h3,
  h5,
  h6 {
    margin-block-end: 0em;
    margin-block-start: 0em;
    color: rgb(100, 110, 109);
    direction: ${(props) => (props.dir ? "rtl" : "ltr")};
  }

  p {
    color: rgb(98, 109, 102);
    word-wrap: break-word;
  }
`;

const ContentsWrap = styled.div`
  padding: 16px;
`;

const CardTitle = styled.div`
  display: flex;
  width: auto;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${(props) => (props.dir ? "row-reverse" : "row")};
  h3 {
    color: #0d0d86;
  }
`;

const TextContent = styled.div`
  max-height: ${(props) => (props.open ? "100%" : "70px")};
  overflow: hidden;
  width: inherit;
`;

function MultiLineText({ text, dir }) {
  // 문자열을 줄바꿈 기호 기준으로 분할
  const lines = text.split("\n");

  return (
    <div>
      {lines.map((line, index) => (
        <p dir={dir} key={index}>
          {line}
        </p> // 각 줄을 p 태그로 감싸서 렌더링
      ))}
    </div>
  );
}

const My = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.comment.data);
  const { id, list } = useSelector((state) => state.user);
  const isRtl = useSelector((state) => state.locale.isRtl);

  const locale = isRtl ? "rtl" : "ltr";

  useEffect(() => {
    dispatch(fetchComData(id));
  }, []);

  // console.log(id, list, data);

  if (!list) {
    return (
      <NoUser>
        <NoUserText>
          <h4>This service requires a manager.</h4>
        </NoUserText>
        <ButtonNav to="/">Home</ButtonNav>
      </NoUser>
    );
  }

  const Comment = (props) => {
    const [open, setOpen] = useState(false);
    const toggleOpen = () => {
      setOpen(!open);
    };
    return (
      <CommentCard
        key={props.data.idx}
        open={open}
        onClick={toggleOpen}
        dir={isRtl}
      >
        <ContentsWrap>
          <CardTitle dir={isRtl}>
            {props.data.title ? (
              <h3>{props.data.title}</h3>
            ) : (
              <h3>{props.data.midx.slice(0, 10)}</h3>
            )}
            {open ? <DeletePost idx={props.data.idx} /> : ""}
          </CardTitle>
          <TextContent open={open}>
            {open ? (
              <MultiLineText dir={locale} text={props.data.contents} />
            ) : (
              <MultiLineText
                dir={locale}
                text={props.data.contents.slice(0, 20)}
              />
            )}
          </TextContent>
          {open ? <h5>{props.data.wdate}</h5> : <h5>...more</h5>}
        </ContentsWrap>
      </CommentCard>
    );
  };

  const comments = data.map((comment) => (
    // <Comment
    //   key={comment.idx}
    //   name={comment.table_wdaterQnusername}
    //   createdAt={comment.wdate}
    //   text={comment.contents}
    // />
    <Comment key={comment.idx} data={comment} />
  ));

  return (
    <MyWrap>
      <MyTitle>
        <h3>My Meditations</h3>
      </MyTitle>
      {comments}
    </MyWrap>
  );
};

export default My;

import React, { useState } from "react";
import styled from "styled-components";

const SenderWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 80px;
  margin-bottom: 120px;
  gap: 12px;
  align-items: center;
  justify-content: center;
`;

const NumInput = styled.input`
  height: 24px;
`;

const TextInput = styled.textarea`
  /* height: 40px; */
  resize: none;
`;

const Toast = styled.div`
  position: fixed;
  display: ${(props) => (props.isDone ? "block" : "none")};
  border-radius: 16px;
  margin: auto;
  padding: 20px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  animation: slideUp 0.3s;

  @keyframes slideUp {
    from {
      transform: translateY(120%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

const Sender = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [messageContent, setMessageContent] = useState("");
  const [response, setResponse] = useState(null);
  const [isDone, setIsDone] = useState(false);

  const handleDone = () => {
    setTimeout(() => setIsDone(false), 3000);
  };

  const postData = async () => {
    const apiUrl = "https://api.blueticks.co/messages";

    const requestBody = {
      apiKey: "GasEdQKKwqWkjGCTAHeRo8ttNr337Fg8",
      to: phoneNumber,
      message: messageContent,
    };

    try {
      const fetchOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      };

      const response = await fetch(apiUrl, fetchOptions);
      const responseData = await response.json();
      setResponse(responseData);
      setIsDone(true);
      handleDone(); // 이 부분에서 handleDone을 호출하도록 수정
    } catch (error) {
      console.error("POST request error:", error);
    }
  };

  return (
    <SenderWrap>
      <NumInput
        type="tel"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        placeholder="전화번호를 입력하세요"
      />
      <TextInput
        type="text"
        value={messageContent}
        onChange={(e) => setMessageContent(e.target.value)}
        placeholder="메시지 내용을 입력하세요"
        rows={10}
        cols={20}
      ></TextInput>
      <button onClick={postData}>WhatsApp 전송</button>
      {response && <Toast isDone={isDone}>문자를 보냈습니다.</Toast>}
    </SenderWrap>
  );
};

export default Sender;

import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchApiData } from "../../reducer/data";
import styled from "styled-components";
import Title from "./Title";
import Audio from "../Audio";
import {
  LOCALE_ARABIC,
  LOCALE_DARI,
  LOCALE_LEVANTINE,
  LOCALE_TURKIYE,
} from "../../reducer/locale";
import moment from "moment";
import { CUSTOM_URL } from "../../reducer/customUrl";
import BibleText from "./BibleText";
import { USER_DATA_ID, USER_DATA_MIDX, getUserData } from "../../reducer/user";
import { fetchComData } from "../../reducer/comment";
import { Helmet } from "react-helmet-async";

const ContentCard = styled.div`
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: ${(props) =>
      props.fontFamily ? "Noto Sans Arabic" : "Noto Sans"},
    sans-serif;

  h3 {
    font-size: 23pt;
    color: #0d0d86;
  }

  h3,
  h5,
  h6 {
    margin-block-end: 0em;
    margin-block-start: 0em;
  }

  h5 {
    color: #0d0d86;
    margin: 0 8px;
  }
  h6 {
    color: #333333;
  }

  p {
    color: #333333;
    line-height: 33pt;
    font-size: 12pt;
  }

  b {
    font-weight: 600;
    color: #0d0d86;
  }
  @media (orientation: portrait) {
    h3 {
      font-size: 1.5em;
    }
    p {
      font-size: 1em;
      line-height: 1.9;
    }
    /* margin-left: 16px;
    margin-right: 16px; */
  }
`;

const Bible = () => {
  const selectedDate = useSelector((state) => state.calendar.selectedDate);
  const formattedDate = moment(selectedDate).format("YYYY-MM-DD");

  const selectedLang = useSelector((state) => state.locale.lang);

  const audioUrl = useSelector((state) => state.data.data[0]?.zaodurl);
  const locale = useSelector((state) => state.locale.isRtl);
  const error = useSelector((state) => state.data.error);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const lang = searchParams.get("lang");
  const paramQday = searchParams.get("qday");
  const midx = searchParams.get("midx");
  const uinfo = searchParams.get("uinfo");
  const all = location.pathname + location.search;

  const langList = {
    fusha: "ar",
    levantine: "ar",
    turkiye: "tr",
    dari: "fa",
  };

  const componentDate = paramQday
    ? moment(paramQday).format("DD-MM-YYYY")
    : moment(selectedDate).format("DD-MM-YYYY");

  useEffect(() => {
    if (!lang || lang === "fusha") {
      dispatch({
        type: LOCALE_ARABIC,
      });
    } else if (lang === "turkiye") {
      dispatch({
        type: LOCALE_TURKIYE,
      });
    } else if (lang === "dari") {
      dispatch({
        type: LOCALE_DARI,
      });
    } else if (lang === "levantine") {
      dispatch({
        type: LOCALE_LEVANTINE,
      });
    }

    if (selectedDate && (lang || midx || uinfo)) {
      const qday = paramQday ? `&qday=${paramQday}` : `&qday=${formattedDate}`;
      const b = lang ? `&lang=${lang}` : "";
      const c = midx ? `&midx=${midx}` : "";
      const d = uinfo ? `&uinfo=${uinfo}` : "";
      const urlParam = `https://121bible.com/_mis/list_json.php?flag=readResult&RealPid=121bible003859&pure=1${
        qday + b + c + d
      }`;
      // console.log("patch data reducer url :", urlParam);
      dispatch(fetchComData(uinfo));
      dispatch({ type: USER_DATA_ID, payload: uinfo });
      dispatch({ type: USER_DATA_MIDX, payload: midx });
      dispatch(fetchApiData(urlParam));
      dispatch({
        type: CUSTOM_URL,
        payload: all,
      });
    }

    if (selectedDate || paramQday) {
      const qday = paramQday ? `&qday=${paramQday}` : `&qday=${formattedDate}`;
      const url = `https://121bible.com/_mis/list_json.php?flag=readResult&RealPid=121bible003859&pure=1${qday}`;
      dispatch(fetchApiData(url));
    }

    const uurl = `https://121bible.com/_oauth/uinfo.php?isList=${uinfo}`;
    dispatch(getUserData(uurl));
  }, [selectedDate, selectedLang]);

  if (error) {
    console.log(error);
    dispatch({
      type: CUSTOM_URL,
      payload: "/",
    });
    return navigate("/404", { replace: true });
  }

  return (
    <>
      <Helmet>
        <html lang={langList[lang] || "ar"} />
      </Helmet>
      <ContentCard fontFamily={locale} id="bible">
        <h5>{componentDate}</h5>
        <Title />
        <Audio url={audioUrl} />
        <BibleText />
        <div id="ifr_relay" value={"123"}></div>
      </ContentCard>
    </>
  );
};

export default Bible;

import { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Modal from "./Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { OPEN_CALENDAR, OPEN_LANGUAGE } from "../reducer/modal";
import { ReactComponent as CalIco } from "../img/cal.svg";
import { ReactComponent as GlobeIco } from "../img/globe.svg";

const NavBody = styled.div`
  width: 100%;
  height: ${(props) => (props.isOver ? "140px" : "180px")};
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: -100px;
  z-index: 5;
  /* background-color: #f5fdff; */

  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);

  /* backdrop-filter: ${(props) => (props.isOver ? "blur(15px)" : "")};
  -webkit-backdrop-filter: ${(props) => (props.isOver ? "blur(15px)" : "")}; */

  transition: height 0.5s cubic-bezier(0.51, 0.25, 0, 0.97);

  h3 {
    transition: font-size 0.5s cubic-bezier(0.51, 0.25, 0, 0.97);
    font-size: ${(props) => (props.isOver ? "16pt" : "23pt")};
    color: #0d0d86;
  }
`;

const PwaBox = styled.div`
  height: 100px;
`;

const NavContentsWrap = styled.div`
  width: 100%;
  max-width: 630px;
  height: ${(props) => (props.isOver ? "40px" : "80px")};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: height 0.5s cubic-bezier(0.51, 0.25, 0, 0.97);
`;

const Menu = styled.div`
  /* position: absolute;
  left: 0; */
  width: ${(props) => (props.isOver ? "0" : "54px")};
  height: ${(props) => (props.isOver ? "0" : "54px")};
  opacity: ${(props) => (props.isOver ? "0" : "1")};
  border-radius: 12px;
  margin: 5px 16px 4px 16px;
  margin-right: 16px;
  transition: height 0.3s cubic-bezier(0.51, 0.25, 0, 0.97),
    width 0.3s cubic-bezier(0.51, 0.25, 0, 0.97),
    opacity 0.2s cubic-bezier(0.51, 0.25, 0, 0.97);
  cursor: pointer;
  &:hover {
    background-color: rgb(217 234 255);
  }
`;

const BlankBox = styled.div`
  width: ${(props) => (props.isOver ? "0" : "54px")};
  height: ${(props) => (props.isOver ? "0" : "54px")};
  margin: 5px 16px 4px 16px;
  margin-right: 16px;
  transition: height 0.3s cubic-bezier(0.51, 0.25, 0, 0.97),
    width 0.3s cubic-bezier(0.51, 0.25, 0, 0.97);
`;

const ShadowBox = styled.div`
  width: 100%;
  height: 15px;
  overflow: hidden;
  position: fixed;
  top: ${(props) => (props.isOver ? "39px" : "79px")};
  transition: top 0.3s cubic-bezier(0.51, 0.25, 0, 0.97);
  z-index: 99;
`;

const Shadow = styled.div`
  width: 100%;
  box-shadow: 0px 0px 12px 5px rgba(0, 0, 0, 0.15);
  opacity: ${(props) => (props.isOver ? "1" : "0")};
  position: absolute;
  top: 0px;
  transition: opacity 0.2s cubic-bezier(0.51, 0.25, 0, 0.97);
`;

const pathName = {
  bible: "Bible",
  meditation: "Meditation",
  comments: "Comments",
  post: "Post",
};

const NavTitle = () => {
  const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const uinfo = searchParams.get("uinfo");

  if (location.pathname === "/") {
    return <h3>{pathName.bible}</h3>;
  } else {
    return <h3> {location.pathname.substring(1)}</h3>;
  }
};

const Navbar = () => {
  const [outTop, setOutTop] = useState(false);
  const dispatch = useDispatch();
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const uinfo = searchParams.get("uinfo");
  const uinfo = useSelector((state) => state.user.id);

  const openCalendar = () => {
    dispatch({ type: OPEN_CALENDAR });
  };

  const openLanguage = () => {
    dispatch({ type: OPEN_LANGUAGE });
  };

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      // const element = document.querySelector("#bible");
      // const rect = element.getBoundingClientRect();
      // console.log(rect.top);
      if (position > 10) {
        setOutTop(true);
      } else {
        setOutTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <NavBody isOver={outTop}>
        <PwaBox />
        <NavContentsWrap isOver={outTop}>
          <Menu isOver={outTop} onClick={openCalendar}>
            <CalIco />
          </Menu>
          <NavTitle />
          {uinfo ? (
            <BlankBox isOver={outTop} />
          ) : (
            <Menu isOver={outTop} onClick={openLanguage}>
              <GlobeIco />
            </Menu>
          )}
        </NavContentsWrap>
      </NavBody>
      <ShadowBox isOver={outTop}>
        <Shadow isOver={outTop} />
      </ShadowBox>
      <Modal />
    </>
  );
};

export default Navbar;

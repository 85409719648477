import { createStore, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import rootReducer from "../reducer";

const loggerMiddleware = (store) => (next) => (action) => {
  console.log("이전 상태:", store.getState());
  console.log("액션:", action);

  const result = next(action);

  console.log("다음 상태:", store.getState());
  return result;
};

const store = createStore(
  rootReducer,
  // test level
  // applyMiddleware(thunk, loggerMiddleware)

  //production level
  applyMiddleware(thunk)
);

// store.subscribe(() => {
//   console.log(store.getState());
// });

export default store;

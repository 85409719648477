import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as NoDataImg } from "../img/NoData.svg";
import { CUSTOM_URL } from "../reducer/customUrl";

const text = {
  fusha:
    "حاليًا، نحن نقدم فقط تأملات مخصصة. يعمل خبراؤنا بجد لضمان توفير خدمات المستخدم المفتوح!",
  levantine:
    "حاليًا، نحن نقدم فقط تأملات مخصصة. يعمل خبراؤنا بجد لضمان توفير خدمات المستخدم المفتوح!",
  dari: "در حال حاضر، ما فقط مراقبه های شخصی شده را ارائه می دهیم. کارشناسان ما سخت کار می کنند تا خدمات کاربر باز را تضمین کنند!",
  turkiye:
    "Şu anda yalnızca özelleştirilmiş meditasyonlar sağlıyoruz. Uzmanlarımız Açık Kullanıcı hizmetlerinin de sağlanmasını sağlamak için çok çalışıyor!",
};

const NrWrap = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  h3 {
    font-weight: 600;
    color: #0d0d86;
  }
`;

const GoHome = styled.div`
  width: 200px;
  height: 40px;
  border-radius: 8px;
  background-color: #0d0d86;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 1em;
  color: white;
  cursor: pointer;
  margin-top: 40px;
`;

const NotReady = () => {
  const lang = useSelector((state) => state.locale.lang);
  const rtl = useSelector((state) => state.locale.isRtl);
  const navigate = useNavigate();

  const deleteUrl = () => {
    navigate("/");
    window.location.reload();
  };

  return (
    <NrWrap>
      <NoDataImg alt="data not ready" loading="lazy" />
      <h3 dir={rtl ? "rtl" : "ltr"}>{text[lang]}</h3>
      <GoHome onClick={deleteUrl}>GO HOME</GoHome>
    </NrWrap>
  );
};

export default NotReady;

import React from "react";
import { useSelector } from "react-redux";
import { ListBox, LocaleWrap } from "./Locale.style";
import { useLocation, useNavigate } from "react-router-dom";

const Locale = () => {
  const url = useSelector((state) => state.url.customUrl);
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramUinfo = searchParams.get("uinfo");
  const paramLang = searchParams.get("lang");

  const handleSelect = (event) => {
    const val = event.target.value;
    console.log(val);
    if (url === "/" || paramLang) {
      navigate(`/?lang=${val}`);
      window.location.reload();
    } else if (paramUinfo) {
      navigate(`${url}&lang=${val}`);
      window.location.reload();
    }
  };

  return (
    // <LocaleWrap>
    //   <FormLabel htmlFor="comboBox">🌐</FormLabel>
    //   <FormSelect
    //     id="comboBox"
    //     value={selectedValue}
    //     onChange={handleSelectChange}
    //   >
    //     <FormOption value="fusha">Fusha</FormOption>
    //     <FormOption value="levantine">Levantine</FormOption>
    //     <FormOption value="dari">Dari</FormOption>
    //     <FormOption value="turkiye">Turkiye</FormOption>
    //     {/* 원하는 만큼 옵션을 추가할 수 있습니다. */}
    //   </FormSelect>
    // </LocaleWrap>

    // <LocaleWrap>
    //   <LanguageBox to="/">fusha</LanguageBox>
    //   <LanguageBox to="/?lang=levantine">levantine</LanguageBox>
    //   <LanguageBox to="/?lang=dari">dari</LanguageBox>
    //   <LanguageBox to="/?lang=turkiye">turkiye</LanguageBox>
    // </LocaleWrap>

    <LocaleWrap>
      <ListBox value={"fusha"} onClick={handleSelect}>
        العربية الفصحى
        <> (Fusha)</>
      </ListBox>
      <ListBox value={"levantine"} onClick={handleSelect}>
        العربية الشامية
        <> (Levantine)</>
      </ListBox>
      <ListBox value={"dari"} onClick={handleSelect}>
        دری
        <> (Dari)</>
      </ListBox>
      <ListBox value={"turkiye"} onClick={handleSelect}>
        Türkçe
        <> (Turkish)</>
      </ListBox>
    </LocaleWrap>
  );
};

export default Locale;

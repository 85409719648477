import React from "react";
import Calendar from "react-calendar";
import "./Cal.css";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const CalendarWrap = styled.div`
  animation: slideDown 0.3s;

  @keyframes slideDown {
    from {
      transform: translateY(-120%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

const CalendarPicker = () => {
  const dispatch = useDispatch();
  const selectedDate = useSelector((state) => state.calendar.selectedDate);

  const handleDateChange = (date, event) => {
    event.stopPropagation();
    dispatch({ type: "SELECT_DATE", payload: date });
  };

  const today = new Date();
  const isDateClickable = (date) => {
    return date >= today;
  };

  const locale = "en-US";
  const yearArw = null;

  return (
    <CalendarWrap>
      <Calendar
        tileDisabled={({ date }) => isDateClickable(date)}
        value={selectedDate}
        onChange={handleDateChange}
        locale={locale}
        next2Label={yearArw}
        prev2Label={yearArw}
      />
    </CalendarWrap>
  );
};

export default CalendarPicker;

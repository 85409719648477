export const WRITE_SHARE = "WRITE_SHARE";
export const WRITE_QUESTION = "WRITE_QUESTION";
export const SAVE_EMAIL = "SAVE_EMAIL";
export const SAVE_CONTENTS = "SAVE_CONTENTS";
export const SAVE_TITLE = "SAVE_TITLE";
export const TODAY_SENT = "TODAY_SENT";

const initialState = {
  purpose: "Sharing",
  email: "",
  title: "",
  contents: "",
  todaySent: 0,
};

const writeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_EMAIL:
      return {
        ...state,
        email: action.payload.email,
      };
    case SAVE_TITLE:
      return {
        ...state,

        title: action.payload.title,
      };
    case SAVE_CONTENTS:
      return {
        ...state,

        contents: action.payload.contents,
      };
    case TODAY_SENT:
      return {
        ...state,
        todaySent: action.payload,
      };
    case WRITE_SHARE:
      return {
        ...state,
        purpose: "Sharing",
      };
    case WRITE_QUESTION:
      return {
        ...state,
        purpose: "Question",
      };

    default:
      return state;
  }
};

export default writeReducer;

import styled from "styled-components";
// import { Link } from "react-router-dom";

export const LocaleWrap = styled.div`
  min-width: 300px;
  right: 1px;
  background-color: #efefef;
  border-radius: 16px;
  margin: 16px;
  animation: slideDown 0.15s;

  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  @keyframes slideDown {
    from {
      transform: translateY(-120%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

export const ListBox = styled.button`
  cursor: pointer;
  background-color: white;
  border-radius: 10px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0d0d86;
  text-decoration: none;
  border: 0;
  font-size: 1em;
  &:hover {
    background-color: #0d0d86;
    color: white;
  }
`;

// export const LocaleWrap1 = styled.div`
//   min-width: fit-content;
//   min-height: fit-content;

//   z-index: 99;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;

//   background-color: white;
//   border-radius: 16px;

//   animation: slideDown 0.2s;

//   padding: 16px;
//   gap: 8px;
//   margin-top: 20px;
//   @keyframes slideDown {
//     from {
//       transform: translateY(-120%);
//     }
//     to {
//       transform: translateY(0);
//     }
//   }
// `;

// export const FormLabel = styled.label`
//   font-size: 1.2em;
//   text-align: center;
// `;

// export const FormSelect = styled.select`
//   margin: 0px 16px;
//   font-size: 1em;
//   height: 45px;
//   width: 250px;
//   border-radius: 8px;
//   border: none;
//   padding: 8px;
//   appearance: none;
//   cursor: pointer;
//   text-align: center;
//   background-color: #ddd;
// `;

// export const FormOption = styled.option`
//   margin: 0px 16px;
//   font-size: 1em;
//   width: 250px;
//   padding: 8px;
//   appearance: none;
//   cursor: pointer;
//   text-align: center;
//   background-color: #ddd;
// `;

// export const LanguageBox = styled(Link)`
//   width: 200px;
//   height: 40px;
//   border-radius: 8px;
//   background-color: #0d0d86;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   text-decoration: none;
//   font-size: 1em;
//   color: white;
//   cursor: pointer;
// `;

// import { createSlice, Reducer } from "redux";

export const SELECT_DATE = "SELECT_DATE";

const initialState = {
  selectedDate: new Date(),
};

export const selectDate = (date) => ({
  type: "SELECT_DATE",
  payload: date,
});

export const dateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_DATE:
      return {
        ...state,
        selectedDate: action.payload,
      };
    default:
      return state;
  }
};

// const CalendarSlice = createSlice({
//   name: "calendar",
//   initialState,
//   reducer,
// });

// export const { selectedDate } = CalendarSlice.actions;
// export const selectSelectedDate = (state) => state.calendar.selectedDate;

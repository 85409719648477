import React from "react";
import { ReactComponent as Nfimage } from "../img/nf.svg";
import styled from "styled-components";
import { Link } from "react-router-dom";

const PageNotWrap = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;

  h3 {
    font-weight: 600;
    color: #0d0d86;
    text-align: center;
  }
`;

const GoHome = styled(Link)`
  width: 200px;
  height: 40px;
  border-radius: 8px;
  background-color: #0d0d86;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 1em;
  color: white;
  cursor: pointer;
  margin-top: 40px;
`;

const AccessDenied = () => {
  return (
    <PageNotWrap>
      <Nfimage alt="Page Not Found image" loading="lazy" />
      <h3>
        Are you having any problems?
        <br />
        Please contact us.
        <br />
        👉 <Link to="mailto:moses.lee@lcanvas.com">email</Link> 👈
      </h3>

      <GoHome to="/">GO HOME</GoHome>
    </PageNotWrap>
  );
};

export default AccessDenied;

import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as deleteIco } from "../../img/delete.svg";
import styled from "styled-components";

const DeleteIco = styled(deleteIco)`
  width: 24px;
  height: 24px;
  stroke: #0d0d86;
  cursor: pointer;
  &:hover {
    stroke: #7575ff;
  }
`;

const DeletePost = ({ idx }) => {
  const navigate = useNavigate();
  const customUrl = useSelector((state) => state.url.customUrl);

  const formData = new FormData();
  formData.append("key_aliasName", "idx");
  formData.append("deleteList", `["${idx}"]`);
  formData.append("ActionFlag", "delete");
  formData.append("RealPid", "121bible003855");
  formData.append("parent_idx", "midx");

  console.log(formData.get("deleteList"));

  const token =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiIxMjFiaWJsZS5jb20iLCJpYXQiOiIxNzExMjk0Mzg5IiwibmN0IjoxNzExMzM1MDUyLCJyZW1lbWJlciI6Im9mZiIsImV4cCI6IjE3MjY4NDYzODkiLCJhdXRoX3ZlcnNpb24iOiIxNTk5NDk1MDMzIiwiTWlzU2Vzc2lvbl9Vc2VySUQiOiJnYWRtaW4iLCJNaXNTZXNzaW9uX1VzZXJOYW1lIjoiZ2FkbWluIiwiTWlzU2Vzc2lvbl9BdXRoU2l0ZSI6ImhvbWUiLCJteUxhbmd1YWdlQ29kZSI6ImtvLUtSIiwiTWlzU2Vzc2lvbl9TdGF0aW9uTmFtZSI6IlJPT1QiLCJNaXNTZXNzaW9uX1N0YXRpb25OdW0iOjI3MiwiTWlzU2Vzc2lvbl9Qb3NpdGlvbk5hbWUiOiJNSVMlRUMlQjUlOUMlRUElQjMlQTAlRUElQjQlODAlRUIlQTYlQUMlRUMlOUUlOTAiLCJNaXNTZXNzaW9uX1Bvc2l0aW9uQ29kZSI6MX0.jwD6uJ8yaSIro6PoGrT65HQekP9w92qECibbv2VdZgg";
  const url = `https://121bible.com/_mis/save.php?parent_gubun=3849&parent_idx=${formData.midx}&accessToken=${token}`;

  const deleteHandler = async () => {
    const result = window.confirm("Do you really want to Delete this Post?");
    if (result === true) {
      try {
        const response = await fetch(url, {
          method: "POST",
          body: formData,
        });

        const result = await response.json();
        const rr = result["resultCode"];
        console.log(rr);
      } catch (error) {
        console.error("Error during save operation:", error);
        alert("저장 중 오류가 발생했습니다.");
      }
      navigate(customUrl ? customUrl : "/");
    } else {
      return;
    }
  };

  return (
    <div onClick={deleteHandler}>
      <DeleteIco />
    </div>
  );
};

export default DeletePost;
